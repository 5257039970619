/**
 * アニメーション関係
 */
import {
    BREAK_POINT,
    BREAK_POINT_TAB,
    BREAK_POINT_MB,
} from '../utils/_const'

import {
    splitTextForSpan,
    removeHeaderMenuLinkSpanActive,
    currentHeaderMenu
} from '../ui/_index'


/**
 * ヘッダーメニューの表示アニメーション
 */
export function showHeaderMenuLinkAnime () {
    const target = document.querySelectorAll('.js-header-menu-link');

    target.forEach((el) => {
        const item = el.children;
        for (let i = 0; i < item.length; i++) {
            setTimeout(() =>{
                item[i].classList.add('active');
            }, i * 10);
        }
    });

    // ここで対象のヘッダーメニューにアクティブクラスを付与
    setTimeout(() => {
        currentHeaderMenu();
    }, 1000);
}

/**
 * 対象クラスがついていたらホバー時にアニメーションさせながら表示
 */
export function initHeaderMenuHoverAnime() {
    const $hoverTarget = $('.js-header-hover-menu');

    $hoverTarget.on('mouseenter', function() {
        const $hoverItem = $(this).children('.js-header-hover-menu-item');
        if($hoverItem.length > 0) {
            $hoverItem.fadeIn('fast');
        }
    });

    $hoverTarget.on('mouseleave', function() {
        const $hoverItem = $(this).children('.js-header-hover-menu-item');
        if($hoverItem.length > 0) {
            $hoverItem.fadeOut('fast');
        }
    });
}

/**
 * 下層表示時のアニメーション
 */
export function initShowContentAnime () {

    if($('body').attr('id') == "top") {
        // TOP時は動かさない
        return false;
    }

    const TARGET_CLASS = ".js-title-section";
    const TARGET_CLASS_MAIN = ".js-title-section-main";
    const TARGET_CLASS_SUB = ".js-title-section-sub";
    const TARGET_CLASS_BODY = '.js-content-inview';

    const target = document.querySelectorAll(TARGET_CLASS);

    if (!target) {
        return false;
    }

    target.forEach((el) => {
        const main = el.querySelector(TARGET_CLASS_MAIN);
        const sub = el.querySelector(TARGET_CLASS_SUB);

        if(main.hasChildNodes()) {
            main.innerHTML = splitTextForSpan(main);
        }

        if(sub.hasChildNodes()) {
            sub.innerHTML = splitTextForSpan(sub);
        }

    });

    const main = document.querySelectorAll(`${TARGET_CLASS_MAIN} > span`);
    const sub = document.querySelectorAll(`${TARGET_CLASS_SUB} > span`);

    // 対象のローディングdom
    const $targetLoading = $('.js-low-loading');

    // ローディングを表示
    $targetLoading.show();

    // ローディングのタイムライン作成
    const loadingAnimeFirst = anime.timeline();

    loadingAnimeFirst.add({
        targets: '.js-low-loading-bg-black',
        scaleY: 0,
        delay: 1000,
        easing: 'spring(1, 80, 20, 0)',
        begin: function() {

            // ロゴ画像を表示
            $('.js-low-loading-logo').show();

            // メニューについてるアクティブクラスを削除
            removeHeaderMenuLinkSpanActive();
        },
        complete: function() {
        }
    }).add({
        targets: '.js-low-loading-bg-white',
        scaleY: 0,
        easing: 'spring(1, 80, 20, 0)',
        begin: function(anim) {

            // ロゴ画像を非表示
            $('.js-low-loading-logo').hide();


            // メニューを表示
            // 気持ち早め
            setTimeout(function() {
                showHeaderMenuLinkAnime();
            }, 300);

            // 開始後500ms後に下層の見出し表示
            setTimeout(function() {
                // 下層の大見出しと小見出しを表示
                main.forEach((el, idx) => {
                    setTimeout(() =>{
                        el.classList.add('active');
                    }, idx * 10);
                });

                sub.forEach((el, idx) => {
                    setTimeout(() =>{
                        el.classList.add('active');
                    }, idx * 10);
                });
            }, 500);

            // コンテンツ側は見出しよりも遅らせる
            setTimeout(function() {
                document.querySelector(TARGET_CLASS_BODY).classList.add('active');

                // 記事詳細でsnsのシェアボタンがあれば表示
                const snsShare = document.querySelector('.js-sns-share');
                if(snsShare) {
                    snsShare.classList.add('active');
                }
            }, 1000);

        },
        complete: function() {
            // ローディングのラッパーを非表示
            $targetLoading.hide();

            // 背景の黒を戻す
            $('.js-low-loading-bg-black').css('transform', 'scaleY(1)');

            // 背景の白を戻す
            $('.js-low-loading-bg-white').css('transform', 'scaleY(1)');

        }
    }, '-=500');
}

/**
 * 画面遷移前にTOPに遷移する前にローディング用のDOMを生成しておく
 */
export function initLoadingBeforeAnimation (before, after) {
    const $after = $(after);

    // 下層からの遷移
    if ($after.find('.js-top-hero').length > 0) {
        // TOPへの遷移
        $('body').append($('<div class="top-loading-overlay js-top-loading-overlay"></div>'))
    }
}


/**
 * 画面遷移後のローディングアニメーション処理
 */
export function initLoadingAfterAnimation () {
    const $loading = $('.js-top-loading')
    let loadedImageCount = 0
    let isLoadedImages = false
    let timer = 0

    // TOPのKVで使う画像
    const imageList = [
        { src: '/images/top/hero/kv-1-pc.jpg'},
        { src: '/images/top/hero/kv-2-pc.jpg'},
        { src: '/images/top/hero/kv-3-pc.jpg'},
        { src: '/images/top/hero/kv-1-tab.jpg'},
        { src: '/images/top/hero/kv-2-tab.jpg'},
        { src: '/images/top/hero/kv-3-tab.jpg'},
        { src: '/images/top/hero/kv-1-sp.jpg'},
        { src: '/images/top/hero/kv-2-sp.jpg'},
        { src: '/images/top/hero/kv-3-sp.jpg'},
    ]

    /**
     * KVで使う画像を読み込む
     */
    function loadKvImage () {
        for (let i = 0; i < imageList.length; i++) {
            const $img = $('<img/>')
            $img.on('load', () => {
                loadedImageCount += 1
                setTimeout(animeStart, 200)
            })
            $img.attr('src', imageList[i].src);
        }
    }

    /**
     * ロゴのアニメーション開始
     */
    function animeStart () {
        if (loadedImageCount < imageList.length) {
            return false
        }

        setTimeout(() => {
            isLoadedImages = true
        }, 300)
    }

    /**
     * アニメーション終了時のアニメーション
     */
    function loadedAnimation () {
        if (isLoadedImages) {
            clearInterval(timer);

            // 下層からTOP遷移時に追加したローディングのオーバーレイを削除
            const $appendedOverlay = $('.js-top-loading-overlay');
            if($appendedOverlay.length > 0) {
                $('.js-top-loading-overlay').remove();
            }

            // SVGのロゴアニメーション実行
            const $svgLogo = $('#loading-logo');
            $svgLogo.addClass('active');
            let isLogoOpacity = false;

            // ローディングの処理
            const $topLoading = $('.js-top-loading');

            // タイムライン作成
            const loadingAnime = anime.timeline();

            // ヘッダーのメニューが表示されているかのフラグ
            let isHeaderMenu = false;

            // スライドの1枚目と3枚目のスライドのスケールを調整する
            const $slide1 = $('.js-hero-image-1 img');
            const $slide3 = $('.js-hero-image-3 img');
            const width = window.innerWidth;
            const imgWidth = $slide1.width();
            if(width == imgWidth) {
                $slide1.css('transform', 'scale(1.2)');
                $slide3.css('transform', 'scale(1.2)');
            }

            /**
             * easingがspringの場合はdurationは無視されるので注意
             */
            loadingAnime.add({
                targets: '.js-top-loading-bg-black',
                scaleY: 0,
                delay: 1000,
                easing: 'spring(1, 80, 20, 0)',
                begin: function () {
                    // メニューについてるアクティブクラスを削除
                    removeHeaderMenuLinkSpanActive();
                },
                update: function (anime) {
                    if(!isLogoOpacity && anime.progress > 50) {
                        isLogoOpacity = true;
                        $("#loading-logo-group").addClass("active");
                    }
                },
                complete: function() {
                }
            }).add({
                targets: '.js-top-loading-bg-white',
                scaleY: 0,
                easing: 'spring(1, 80, 20, 0)',
                begin: function(anim) {
                    $svgLogo.hide();
                },
                update: function(anim) {
                    if(!isHeaderMenu && Math.round(anim.progress) > 10) {
                        isHeaderMenu = true;

                        // KVの初回アニメーションを開始
                        kvFirstAnimation();
                    }
                },
                complete: function() {
                    // ローディングラッパーを削除
                    $topLoading.remove();
                }
            }, '-=500');
        }
    }

    /**
     * FV周の初回用アニメーション
     */
    function kvFirstAnimation () {
        const $catchText1 = $(".js-hero-catch-1");
        const $catchText2 = $(".js-hero-catch-2");
        const $scrollCircle = $('.js-hero-circle');

        let isHeaderMenu = false;

        // グローバルメニューの表示開始時の閾値
        const showHeaderMenuProgress = 50;

        anime({
            targets: '.js-hero-main',
            scale: [1.5, 1],
            duration: 2500,
            easing: 'easeOutExpo',
            begin: function() {
                $catchText1.children("span").each(function (idx, el){
                    setTimeout(() => {
                        $(el).addClass("active");
                    }, idx * 10);
                });

                $catchText2.find("div > span").each(function (idx, el){
                    setTimeout(() => {
                        $(el).addClass("active");
                    }, idx * 10);
                });
            },
            update: function(anime) {

                // 閾値を超えたらメニューを表示
                if(!isHeaderMenu  && anime.progress > showHeaderMenuProgress) {
                    isHeaderMenu = true;

                    // メニューを表示
                    showHeaderMenuLinkAnime();
                }
            },
            complete: function() {

                $scrollCircle.addClass("active");

                kvLoopAnimation();
            }
        });
    }

    /**
     * FVの初回表示完了後のループ用アニメーション
     */
    function kvLoopAnimation () {

        // アニメーション対象のimg
        const $targetImg1 = $('.js-hero-image-1 img');
        const $targetImg2 = $('.js-hero-image-2 img');
        const $targetImg3 = $('.js-hero-image-3 img');

        /**
         * 画像の切り替えアニメーション
         */
        function slideAnime() {

            // PC / SPの幅で共通durationの値を変更
            let duration = 6000;
            let keyFrame1 = 1000;
            let keyFrame2 = 4000;
            let offSet = '-=2000';

            if (window.innerWidth <= BREAK_POINT_TAB) {
                duration = 12000;
                keyFrame1 = 1000;
                keyFrame2 = 8000;
                offSet = '-=4000';
            }

            // スライドをリセットする
            resetSlide();

            // 画像アニメーションのタイムライン作成
            const scene = anime.timeline({
                easing: 'linear',
                duration: duration,
                loop: false,
            });

            scene.add({
                targets: '.js-hero-image-1',
                opacity: [
                    { value: 1},
                    { value: 1, duration: keyFrame1 },
                    { value: 1, duration: keyFrame2 },
                    { value: 0},
                ],
                update: function (anime) {
                    if (isScale()) {
                        // PC時
                        slideScale($targetImg1, 'down', anime.progress);
                    } else {
                        // SP時
                        slideMove($targetImg1, 'left', anime.progress);
                    }
                },
            }).add({
                targets: '.js-hero-image-2',
                opacity: [
                    { value: 0},
                    { value: 1, duration: keyFrame1 },
                    { value: 1, duration: keyFrame2 },
                    { value: 0},
                ],
                update: function (anime) {
                    if (isScale()) {
                        // PC時
                        slideScale($targetImg2, 'up', anime.progress);
                    } else {
                        // SP時
                        slideMove($targetImg2, 'right', anime.progress);
                    }
                },
            }, offSet).add({
                targets: '.js-hero-image-3',
                opacity: [
                    { value: 0},
                    { value: 1, duration: keyFrame1 },
                    { value: 1, duration: keyFrame2 },
                    { value: 0},
                ],
                update: function (anime) {
                    if (isScale()) {
                        // PC時
                        slideScale($targetImg3, 'down', anime.progress);
                    } else {
                        // SP時
                        slideMove($targetImg3, 'left', anime.progress);
                    }
                },
                complete: function() {
                    // 終了後のアニメーション実行
                    slideEndAnime();
                }
            }, offSet)
        }

        /**
         * ループが最後までいった後に動かすアニメーション
         */
        function slideEndAnime() {

            const $target1 = $('.js-hero-loading-1');
            const $target2 = $('.js-hero-loading-2');

            // 画像終了前のアニメーション
            const overlay = anime.timeline({
                duration: 1000,
                easing: 'spring(1, 80, 20, 0)',
                loop: false,
            });

            overlay.add({
                targets: '.js-hero-loading-1',
                scaleY: [0, 1],
                complete: function() {
                    // 起点を上中央に変更
                    $target1.css('transform-origin', 'center top');

                    // 白背景を表示
                    $target2.css('transform', 'scaleY(1)');

                    // このタイミングでKV1枚目のopacityを戻す
                    $('.js-hero-image-1').css('opacity', '1');

                }
            }).add({
                targets: '.js-hero-loading-1',
                scaleY: [1, 0],
            }).add({
                targets: '.js-hero-loading-2',
                scaleY: [1, 0],
                begin: function() {

                    // タイミングはdelayで調整
                    anime({
                        targets: '.js-hero-main',
                        scale: [1.5, 1],
                        delay: 300,
                        duration: 2500,
                        easing: 'easeOutExpo',
                        begin() {
                            // スライドをリセットする
                            resetSlide();
                        },
                        complete: function() {

                            // 1枚目から開始
                            slideAnime();
                        }
                    });
                },
                complete: function() {
                    // 起点を下中央に戻す
                    $target1.css('transform-origin', 'center bottom');
                },
            }, '-=500');
        }

        /**
         * スケールの処理
         */
        function slideScale($target, direction, progress) {

            // 画像のスケール
            const scaleInit = 1.2;
            const scaleDelay = 1;

            if(direction == 'up') {
                const scale = 1 + (((scaleInit - 1) * progress / 100) * scaleDelay);
                $target.css('transform', `scale(${scale})`);
            } else {
                const scale = scaleInit - (((scaleInit - 1) * progress / 100) * scaleDelay);
                $target.css('transform', `scale(${scale})`);
            }
        }

        /**
         * スライドの処理
         */
        function slideMove($target, direction, progress) {
            const nowWidth = window.innerWidth;
            const targetWidth = $target.width();
            const movePoint = (targetWidth - nowWidth) / 100;

            if (direction == 'left') {
                $target.css('transform', `scale(1) translate3d(-${movePoint * progress}px, 0, 0)`);
            } else {
                $target.css('transform', `scale(1) translate3d(${(movePoint * -100) + movePoint * progress}px, 0, 0)`);
            }
        }

        /**
         * アニメーションで変更していたスライドの初期化
         */
        function resetSlide() {
            if (isScale()) {
                // PC
                $targetImg1.css('transform', 'scale(1.2)');
                $targetImg3.css('transform', 'scale(1.2)');
            } else {
                // SP
                $targetImg1.css('transform', 'scale(1) translate3d(0, 0, 0)');

                const nowWidth = window.innerWidth;
                const targetWidth = $targetImg2.width();
                const movePoint = (targetWidth - nowWidth) / 100;
                $targetImg2.css('transform', `scale(1) translate3d(${movePoint * -100}px, 0, 0)`);
                $targetImg3.css('transform', 'scale(1) translate3d(0, 0, 0)');
            }
        }

        /**
         * slideがscale化の判定
         * スライドの画像幅とウィンドウ幅が一緒の場合はtrue:スケールアニメーション
         */
        function isScale() {
            const width = window.innerWidth;
            const imgWidth = $targetImg1.width();

            if (width == imgWidth) {
                return true;
            } else {
                return false;
            }
        }

        // アニメーション開始
        slideAnime();
    }

    /**
     * ローディングの初期化処理
     */
    function init () {
        if ($loading.length == 0) {
            // TOPページじゃないので何もせず終了
            return false
        } else {

            // KVで使う画像を読み込む
            loadKvImage();

            // 使う画像の読み込みが全部終わるまで繰り返す
            timer = setInterval(loadedAnimation, 200)
        }
    }

    init()
}
