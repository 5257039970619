/**
 * 共通関数
 */


/**
 * テストサーバーかどうか確認する
 * @return テストサーバーの場合はtrue,それ以外はfalse
 */
export function isTest (): boolean {
    return location.hostname.indexOf('.axdv.me') != -1
}


/**
 * テストサーバーの場合のみコンソールログを表示する
 * @param text
 */
export function log (text: string): void {
    if (isTest()) {
        console.log('[stg only log]', text);
    }
}
