/**
 * aemFrontPluginの処理
 */
declare const bodyScrollLock;

/**
 * 共通inview
 */
export function initComInview () {
    $('.js-com-inview').aemInview({
        offset: "60%"
    });
}

/**
 * スムーススクロールのセットアップ
 */
export function initSmoothScroll () {

    // 初期設定
    const options = {
        speed: 400,
        easing: 'swing',
        offset: -100,
    };

    $('a[href^="#"]').not('.js-pagetop, .no-scroll').on('click', function (e) {
        const $this = $(e.currentTarget);
        const href = $this.attr('href');
        const $target = $(href == '#' || href == '' ? 'html' : href);
        const position = $target.offset().top + options.offset;

        $('html,body').animate({ scrollTop: position }, options.speed, options.easing);
        return false;

    });
}


/**
 * トップのスクロール
 */
export function initSmoothScrollTop () {
    $('.js-pagetop').aemSmoothScroll();
}


/**
 * アコーディオンのセットアップ
 */
export function initAccordion () {
    $('.js-accordion').aemAccordion();
}


/**
 * タイトルのアニメーション
 */
export function initTitleAnimation () {
    const $target = $('.js-title');

    if ($target.length == 0) {
        return false;
    }

    // アニメーション用の事前準備
    $target.each((idx, el) => {
        const $el = $(el);
        const $p = $el.children('span');
        $p.each((idx, el) => {
            const $this = $(el);
            const $span = $this.find('span');
            $span.attr('data-txt', $span.text());
            $this.attr('data-txt', $span.text());
        })
    })

    $target.aemInview({
        offset: "20%"
    });
}


/**
 * メニューの開閉
 */
export function initMenu () {
    // $('.js-tc-target').aemToggleClass();

    const $burgerBtn  = $('.js-trigger-menu');
    const $burgerMenu = $('.js-global-menu');
    const scrollTarget = document.querySelector('.js-global-scroll-target');

    $burgerBtn.on('click', function() {
        $(this).toggleClass('active');
        if($(this).hasClass('active')) {
            $burgerMenu.fadeIn();
            bodyScrollLock.disableBodyScroll(scrollTarget);
        } else {
            $burgerMenu.fadeOut();
            bodyScrollLock.enableBodyScroll(scrollTarget);
        }
    });

    $('.js-tc-remove').on('click', function() {
        $burgerMenu.fadeOut();
        $burgerBtn.removeClass('active');
        bodyScrollLock.enableBodyScroll(scrollTarget);
    });
}


/**
 * ヘッダーのリンクをスクロールで消す
 */
export function initHeaderLink () {
    const $headerLink = $('.js-header-link')
    const $pageTop = $('.js-pagetop')
    const $w = $(window)
    const activeClass = 'js-active'
    const borderPoint = 100

    $w.on('scroll', () => {
        if ($w.scrollTop() > borderPoint) {
            $headerLink.addClass(activeClass)
            $pageTop.addClass(activeClass)
        } else {
            $headerLink.removeClass(activeClass)
            $pageTop.removeClass(activeClass)
        }
    })
}
