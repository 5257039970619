/**
 * 定数
 */


// PC/SPのブレイクポイント
export const BREAK_POINT = 768
export const BREAK_POINT_MB = 599
export const BREAK_POINT_TAB = 1023
export const BREAK_POINT_PC = 1024
