/**
 * メイン処理
 */
import {
    initComInview,
    initSmoothScroll,
    initSmoothScrollTop,
    initAccordion,
    initMenu,
    initHeaderLink
} from './plugins/_aemFrontPlugin'

import initObjectFitPolyfill from './plugins/_objectFit'
import initPjax from './plugins/_pjax'

import {
    initServiceSlider,
    destroyServiceSlick
} from './plugins/_slick'

import {
    initShowContentAnime,
    initLoadingBeforeAnimation,
    initLoadingAfterAnimation,
    initHeaderMenuHoverAnime,
} from './animation/_index'

import {
    initContactForm,
    initMessage,
    followMouseCursor,
    toggleBodyId,
    footerAddDataAttr,
    initHeaderMenuLinkSpan,
    destroyKvAnime,
    removeCurrentHeaderMenu
} from './ui/_index';


/**
 * ベージの表示する前に動かす処理
 */
function before (before?, after?) {
    // 前ページにあった要素の初期化
    destroyServiceSlick();

    // TOPのアニメーション削除
    destroyKvAnime(before);

    // 次ページの要素に付与する処理
    toggleBodyId();
    footerAddDataAttr();
    initHeaderMenuLinkSpan();
    removeCurrentHeaderMenu();
    initComInview();
    initAccordion();
    initShowContentAnime();
    initContactForm();
    initMessage();
    initLoadingBeforeAnimation(before, after);
    initSmoothScroll();
}


/**
 * ページ表示直後に動かす処理
 */
function after (before?, after?) {
    initLoadingAfterAnimation();
    initObjectFitPolyfill()
    initServiceSlider();
}


$(() => {
    // 表示前に動く処理
    before();

    // pjaxに処理を設定
    initPjax({
        beforeCallback: before,　// pjaxの表示前に動く処理（初回は動かない）
        afterCallback: after,　// pjaxの表示後に動く処理（初回も動く）
    });

    // トータルで1回しか動かさない処理
    initMenu();
    initSmoothScrollTop();
    initHeaderLink();
    followMouseCursor();
    initHeaderMenuHoverAnime();
})
