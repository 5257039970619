/**
 * Slick関係の処理
 */
import { log } from '../utils/_util'


/**
 * TOPのWORKSのスライダーの設定
 */
export function initTopSlider () {
    const $w = $(window);
    const $slider = $('.slider');

    if ($slider.length === 0) {
        return false;
    }

    const init = () => {
        let timer = 0;
        const $title = $('.section__title');

        const setLeftPosition = () => {
            if ($w.width() <= 768) {
                $slider.find('.slick-track').css('margin-left', 'auto')
                $('.slide-nav-num').css('left', 'auto')

            } else if ($w.width() <= 1200) {
                const offset = -$('.slick-current').width();
                $slider.find('.slick-track').css('margin-left', offset + 'px')
                $('.slick-prev').css('left', $title.offset().left - 35)
                $('.slick-next').css('left', $title.offset().left + $('.slick-current').width() + 15)
                $('.slide-nav-num').css('left', $('.slick-current').width() - $('.slide-nav-num').width() + 30)

            } else if ($w.width() <= 1500) {
                const offset = ($('.slick-current').width() - $title.width()) / 2;
                $slider.find('.slick-track').css('margin-left', offset + 'px')
                $('.slick-prev').css('left', $title.offset().left - 35)
                $('.slick-next').css('left', $title.offset().left + $('.slick-current').width() + 15)
                $('.slide-nav-num').css('left', $('.slick-current').width() - $('.slide-nav-num').width() + 30)

            } else {
                const offset = ($('.slick-current').width() - $title.width()) / 2;
                $slider.find('.slick-track').css('margin-left', offset + 'px')
                $('.slick-prev').css('left', $title.offset().left - 45)
                $('.slick-next').css('left', $title.offset().left + $('.slick-current').width() + 25)
                $('.slide-nav-num').css('left', $('.slick-current').width() - $('.slide-nav-num').width() + 30)
            }
        }

        function resizeHandle () {
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                setLeftPosition()
            }, 200);
        }

        $('.slide-nav-num--1').text(1)
        $('.slide-nav-num--2').text($('.slider__item').length)

        $slider.on('init', () => {
            setTimeout(() => {
                $w.on('resize', resizeHandle)
                setLeftPosition();
                $('.slide-nav-num').addClass('active')
            }, 200)
        })

        $slider.on('afterChange', () => {
            $('.slide-nav-num').addClass('active')
            $('.slide-nav-num--1').text($slider.slick('slickCurrentSlide') + 1)
        })

        // 左右の透過の2周目ががたつく対応
        $slider.on("beforeChange", (event, slick, currentSlide, nextSlide) => {
            $('.slide-nav-num').removeClass('active');

            // 移動後の前後の要素にも識別用のクラスをつけるのでここで毎回初期化
            $('.is-active-next-prev').removeClass('is-active-next-prev')
            $('.is-active-next-next').removeClass('is-active-next-next')

            $slider.find(".slick-slide").each((index, el) => {
                const $this = $(el)
                const slickindex = $this.attr("data-slick-index")

                if (nextSlide == slick.slideCount - 1 && currentSlide == 0) {
                    // 現在のスライドが最初のスライドでそこから最後のスライドに戻る場合
                    if (slickindex == "-1") {
                        // 最後のスライドに対してクラスを付与
                        $this.addClass("is-active-next");
                        $this.prev().addClass("is-active-next-prev")
                        $this.next().addClass("is-active-next-next")
                    } else {
                        // それ以外は削除
                        $this.removeClass("is-active-next");
                    }
                } else if (nextSlide == 0) {
                    // 次のスライドが最初のスライドの場合
                    if (slickindex == slick.slideCount) {
                        // 最初のスライドに対してクラスを付与
                        $this.addClass("is-active-next");
                        $this.prev().addClass("is-active-next-prev")
                        $this.next().addClass("is-active-next-next")
                    } else {
                        // それ以外は削除
                        $this.removeClass("is-active-next");
                    }
                } else {
                    // それ以外は削除
                    $this.removeClass("is-active-next");
                }
            });
        });

        $slider.on('destroy', function(event, slick){
            $w.off('resize', resizeHandle)
        });

        $slider.slick({
            arrows: true,
            slidesToShow: 3,
            touchThreshold: 10,
            centerMode: true,
            autoplay: true,
            autoplaySpeed: 5000,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        centerPadding: '10%',
                    }
                }
            ]
        })
    }
    init();
}

/**
 * slickの削除
 */
export function destroySlick () {
    if ($('.slider').length > 0 && $('.slider').slick != null) {
        try {
            $('.slider').slick('unslick');
        } catch(e) {
            log("slick設定前")
        }
    }
}

/**
 * サービス詳細のスライダーの設定
 */
export function initServiceSlider () {
    const $w = $(window);
    const $slider = $('.js-service-slick');

    if ($slider.length === 0) {
        return false;
    }

    const init = () => {
        $slider.slick({
            centerMode: true,
            centerPadding: '21%',
            autoplay: true,
            autoplaySpeed: 5000,
            infinite: true,
            pauseOnHover: false,
            arrows: false,
            responsive: [{
                breakpoint: 600,
                settings: {
                    centerPadding: '5%',
                }
            }]
        })
    }

    init();
}

/**
 * サービス詳細のslickの削除
 */
export function destroyServiceSlick () {
    if ($('.js-service-slick').length > 0 && $('.js-service-slick').slick != null) {
        try {
            $('.js-service-slick').slick('unslick');
        } catch(e) {
            log("slick設定前")
        }
    }
}
