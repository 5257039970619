/**
 * UI関係
 */
declare const anime;
import { BREAK_POINT } from '../utils/_const';
import { log } from '../utils/_util'


/**
 * ヘッダーを画面の高さに固定する
 */
export function initHeaderCoverSize () {
    const elHeader = document.querySelector('.hero');
    let defaultWidth;

    if (!elHeader) {
        return false;
    }

    /** 高さをセットする */
    const setHeight = () => {
        if (window.innerWidth <= BREAK_POINT) {
            let offset = 50;
            elHeader.setAttribute('style', 'height: ' + window.innerHeight + 'px');
            defaultWidth = window.innerWidth;
        } else {
            elHeader.setAttribute('style', 'height: ' + window.innerHeight + 'px');
            defaultWidth = window.innerWidth;
        }

    };

    /** 横幅が変わった時のリサイズのみ高さを書き換える */
    let resizeHandle = () => {
        if (defaultWidth !== window.innerWidth) {
            setHeight();
        }
    };

    setHeight();
    window.addEventListener('resize', resizeHandle);
}


/**
 * コンタクトフォームのプライバシーポリシーチェックの処理
 */
export function initContactForm () {
    const $checkbox = $('input[name="privacy_policy"]')
    const $btn = $('.js-submit')

    function check () {
        if ($checkbox.is(':checked')) {
            $btn.prop('disabled', false)
        } else {
            $btn.prop('disabled', true)
        }
    }

    if ($('.js-contact').length > 0) {
        $checkbox.on('change', check);
        check();
    }
}


/**
 * メッセージページの初期化
 */
export function initMessage () {
    let isAnimation = false
    let isHard = true
    const currentClassName = 'current'

    const $toggleBtn = $('.js-message-toggle-btn')
    const $hardBtn = $('.js-message-hard-btn')
    const $softBtn = $('.js-message-soft-btn')

    const $messageWrap = $('.js-message-top')
    const $message = $('.js-message')
    const $hardMessage = $('.js-message-hard')
    const $softMessage = $('.js-message-soft')

    function change () {
        if (isAnimation) {
            return false
        }
        isAnimation = true

        // メッセージを非表示に
        anime({
            targets: $message[0],
            opacity: [1, 0],
            duration: 500,
            easing: "easeInQuad",
        })

        // スクロールしてから切り替え
        anime({
            targets: 'html,body',
            scrollTop: $messageWrap.offset().top - 70,
            duration: 1000,
            easing: "easeInQuad",
            complete () {
                isHard = !isHard
                if (isHard) {
                    // 堅いバージョン
                    $hardBtn.addClass(currentClassName)
                    $softBtn.removeClass(currentClassName)
                    $toggleBtn.html('<span class="c-pink">「</span>本音バージョン<span class="c-pink">」</span>を見てみる')
                    $softMessage.css('display', 'none')
                    $hardMessage.css('display', 'block')
                } else {
                    // 本音バージョン
                    $hardBtn.removeClass(currentClassName)
                    $softBtn.addClass(currentClassName)
                    $toggleBtn.html('<span class="bottom-area__gray">「堅いバージョン」を見てみる</span>')
                    $hardMessage.css('display', 'none')
                    $softMessage.css('display', 'block')
                }
                anime({
                    targets: $message[0],
                    opacity: [0, 1],
                    duration: 500,
                    easing: "easeInQuad",
                })
                isAnimation = false
            }
        })
    }

    if ($messageWrap.length > 0) {
        $toggleBtn.on('click', change)
    }
}

/**
 * 現在のURLに応じてヘッダーメニューにcurrentクラスを付与する
 */
export function currentHeaderMenu () {
    const addClassName = 'current';
    const $target = $('.js-current-header-menu');

    if($target.length == 0) {
        return false;
    }

    // 現在のURLを取得
    const currentUrl = location.pathname;

    // 現在のURLに対応するaタグにcurrentクラスを付与
    $target.each(function() {
        const $this = $(this);
        const href = $this.attr('href');
        if(currentUrl == "/" && href == "/") {
            $this.addClass(addClassName);
        } else if (href !== "/" && currentUrl.indexOf(href) != -1) {
            $this.addClass(addClassName);
        }
    });
}

/**
 * ヘッダーメニューについているcurrentクラスを削除する
 */
export function removeCurrentHeaderMenu () {
    const addClassName = 'current';
    const $target = $('.js-current-header-menu');

    if($target.length == 0) {
        return false;
    }

    // 付与されているcurrentクラスを削除
    $target.each(function() {
        const $this = $(this);
        $this.removeClass(addClassName);
    });
}

/**
 * カーソルの動きに合わせて追受するポインタ
 * 押下できる要素にhoverした時はclassを付与する
 */
export function followMouseCursor () {

    const $target = $('.js-follow-mouse-cursor');

    function mouseMove(event) {
        const x = event.clientX;
        const y = event.clientY;
        $target.css('transform', `translate3d(${x}px, ${y}px, 0)`);
    }

    function mouseEnter(event) {
        $target.addClass('active');
    }

    function mouseLeave(event) {
        $target.removeClass('active');
    }

    $(document).on('mousemove', mouseMove);

    $(document).on({
        'mouseenter' : mouseEnter,
        'mouseleave' : mouseLeave
        }, 'a, button');
}

/**
 * 現在のURLに応じてbodyにid属性を付与する
 */
export function toggleBodyId () {
    const addIdName = 'top';
    const $target = $('body');

    // 現在のURLを取得
    const currentUrl = location.pathname;
    log(currentUrl);

    // 付与されているcurrentクラスを削除
    $target.removeAttr('id');

    // 現在のURLがTOPの場合はidを付与
    if (currentUrl === '/') {
        $target.attr('id', addIdName);
    }
}

/**
 * フッターにdata属性を付与する
 */
export function footerAddDataAttr () {
    const $target = $('#footer');

    // 現在のURLを取得
    const currentUrl = location.pathname;

    if (currentUrl === '/contact/') {
        $target.attr('data-page', 'contact');
    } else {
        $target.attr('data-page', '');
    }
}


/**
 * ヘッダーメニューのリンク名をspanで囲む
 */
export function initHeaderMenuLinkSpan() {
    const header = document.querySelector('.header-menu');
    const $top = document.getElementById('top');

    if(!header || header.classList.contains('js-header-inited') || !$top) {
        // ヘッダーが無い もしくは下層 もしくはすでに初期化sれている場合は何もしない
        return false;
    }

    const target = document.querySelectorAll('.js-header-menu-link');

    if(!target) {
        return false;
    }

    target.forEach((el: any) => {
        el.innerHTML = splitTextForSpan(el);
    });

    header.classList.add('js-header-inited');
}

/**
 * ヘッダーメニューのspanについているactiveクラスを削除する
 */
export function removeHeaderMenuLinkSpanActive() {
    $('.js-header-menu-link span').removeClass('active');
}

/**
 * 渡されたchildNodesをみてテキストをspanで囲む
 * タグだった場合はそのまま
 */
export function splitTextForSpan (target) {

    let text = '';

    target.childNodes.forEach((child: any) => {
        let mkrText = '';

        if(child.nodeType === 3) {
            // 文字列の場合

            mkrText = child.textContent.split('').map((c) => {
                if(c == ' ') {
                    return `<span><span>&nbsp;</span></span>`
                } else {
                    return `<span><span>${c}</span></span>`
                }
            }).join('');

            text += mkrText;

        } else if(child.nodeType === 1){
            // タグの場合はそのまま連結

            text += child.outerHTML;
        }
    });

    return text;
}

/**
 * 下層遷移時にTOPで設定してるアニメーションを削除する
 */
export function destroyKvAnime (before) {
    const $before = $(before);

    // anime.jsで対象にしているクラスを指定
    if($before && $before.find('.js-top-hero').length > 0) {
        anime.remove('.js-hero-main');
        anime.remove('.js-hero-image-1');
        anime.remove('.js-hero-image-2');
        anime.remove('.js-hero-image-3');
        anime.remove('.js-hero-loading-1');
        anime.remove('.js-hero-loading-2');
    }
}
